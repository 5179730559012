<template>
  <div>
    <AppInputFile
      :value.sync="resumeFileInput"
      :loading="loading.resume"
      label="Resume"
      placeholder="Upload your resume in pdf"
      accept="application/pdf"
      class="input file-input"
      @change="changeFile($event, 'resume')"
      @download="downloadFile"
      @delete="deleteFile"
    />
    <AppInputFile
      :value.sync="coverLetterFileInput"
      :loading="loading.coverLetter"
      label="Cover Letter"
      placeholder="Upload your cover letter in pdf"
      accept="application/pdf"
      class="input file-input"
      @change="changeFile($event, 'coverLetter')"
      @download="downloadFile"
      @delete="deleteFile"
    />
    <div v-for="(contact , i) in ticketObj.contacts" :key="i" class="contact-block">
      <AppInput
        :value.sync="contact.name"
        label="Contact:"
        placeholder="Name"
        class="input contact-input"
      />
      <AppInput
        :value.sync="contact.email"
        label="Email address:"
        placeholder="Email"
        class="input email-input"
      />
      <AppInput
        :value.sync="contact.linkedin"
        label="LinkedIn"
        placeholder="URL to LinkedIn Profile"
        class="input linkedin-input"
      />
      <AppTextarea
        :value.sync="contact.communication"
        label="Copy and Paste communication"
        class="communication-textarea"
      />
    </div>
    <div class="add-contact-block">
      <span>Add another contact</span>
      <button class="add-contact-button" @click="addContactBlock">
        <img src="/img/icons/plus-black.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script>
import Fire from '@/plugins/firebase'
import api from '@/api'

export default {
  name: 'TicketModalFilesContacts',
  props: {
    ticket: {
      type: Object,
      required: true
    },
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: {
        resume: false,
        coverLetter: false
      },
      resumeFileInput: null,
      coverLetterFileInput: null,
      userToken: null
    }
  },
  computed: {
    ticketObj: {
      get() {
        return this.ticket
      },
      set(value) {
        this.$emit('update:ticket', value)
      }
    }
  },
  watch: {
    async isModalOpen(value) {
      if (!value) return
      await this.getFiles()
    }
  },
  async created() {
    await this.getFiles()
    const currentUser = await Fire.getCurrentUser()
    this.userToken = await currentUser.getIdToken()
  },
  methods: {
    addContactBlock() {
      this.ticketObj.contacts.push({
        name: '',
        email: '',
        linkedin: '',
        communication: ''
      })
    },
    async getFiles() {
      Object.values(this.loading).forEach(loading => {
        loading = true
      })

      const { data } = await api.users.getUserFiles()

      Object.values(this.loading).forEach(loading => {
        loading = false
      })

      if (!data) return

      this.resumeFileInput = data.find(file => file.fileId === this.ticketObj.resume)
      this.coverLetterFileInput = data.find(file => file.fileId === this.ticketObj.coverLetter)
    },
    downloadFile({ name, fileId }) {
      const a = document.createElement('a')

      a.href = `${process.env.VUE_APP_API_URL}/download/${fileId}?token=${this.userToken}`
      a.setAttribute('download', name)
      a.click()
    },
    async deleteFile({ fileId }) {
      const isConfirmed = confirm('Are you sure you want to delete this file?')

      if (!isConfirmed) return

      await api.users.deleteFile(fileId)
    },
    async changeFile(file, category) {
      this.loading[category] = true

      if (!file) {
        this.loading[category] = false
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const { data, error } = await api.users.addFile(category, formData)

      if (!error) {
        this.ticketObj[category] = data
        this.loading[category] = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-block {
  display: grid;
  margin-top: 24px;
  border-top: 1px solid var(--secondary-border-color);
  padding: 25px 0;
}

.contact-input,
.email-input {
  grid-column: 1 / 3;
}

.linkedin-input,
.communication-textarea {
  grid-column: 1 / 4;
}

.input {
  margin-bottom: 5px;
}

.add-contact-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--secondary-border-color);
  border-bottom: 1px solid var(--secondary-border-color);
  padding: 23px 0;

  .add-contact-button {
    margin-right: 25px;
    width: 24px;
    height: 24px;
  }
}
</style>
