<template>
  <div>
    <span class="date">
      {{ ticketPhaseAndDate }}
    </span>
    <AppTextarea
      v-model="note.description"
      label="Add notes:"
      class="notes-textarea"
      @input="addNote"
    />
    <span class="history-title">History</span>
    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="50"
      width="5"
      indeterminate
    />
    <div v-else class="history-block">
      <span v-if="!notesList.length" class="history-placeholder">
        You will see all dates and your previous notes here.
      </span>
      <template v-else>
        <div v-for="(item, i) in notesList" :key="i" class="history-block-list">
          <div class="history-block-note">
            <span class="note-title">
              <strong>{{ getNotePhaseAndDate(item) }}</strong>
            </span>
            <p class="note-text">
              <span class="note-text-title">Notes:</span>
              {{ item.description }}
            </p>
            <div v-if="item.phase !== 'Wishlist'" class="note-rating">
              <span>My {{ item.phase }} phase rating:</span>
              <v-rating
                :value="item.rate"
                size="16"
                color="var(--rating-star-color)"
                readonly
                dense
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { formatISOdate } from '@/services/dateService'

export default {
  name: 'TicketModalNotesHistory',
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      note: {
        description: '',
        phase: this.ticket.currentPhase
      },
      notesList: [],
      phasesRates: null
    }
  },
  computed: {
    ticketPhaseAndDate() {
      // 'Ticket phase' on: MM/DD/YYYY
      return `${this.ticket.currentPhase} on: ${formatISOdate(this.ticket.date)}`
    }
  },
  async created() {
    await this.fetchNotesList()
    this.fetchTicketRate()
  },
  beforeDestroy() {
    this.note = null
    this.$emit('addNote', this.note)
  },
  methods: {
    formatISOdate,
    addNote() {
      this.$emit('addNote', this.note)
    },
    setPhaseRate() {
      this.notesList.forEach(note => {
        const phaseForNote = this.phasesRates.find(({ phase }) => phase === note.phase)
        note.rate = phaseForNote?.rate
      })
    },
    getNotePhaseAndDate(note) {
      // 'Note phase' on: MM/DD/YYYY
      return `${note.phase} on: ${formatISOdate(note.date)}`
    },
    async fetchNotesList() {
      const { data } = await api.users.getTicketNotes(this.ticket.id)
      this.notesList = data || []
    },
    async fetchTicketRate() {
      this.loading = true
      const { data } = await api.users.getTicketRate(this.ticket.id)
      this.loading = false

      if (data) this.phasesRates = data

      if (this.phasesRates) this.setPhaseRate()
    }
  }
}
</script>

<style lang="scss" scoped>
.date,
.history-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base-lg);
}

.date {
  display: block;
  margin-bottom: 10px;
}

.notes-textarea {
  margin-bottom: 60px;
}

.history-block {
  overflow: hidden;
  overflow-y: auto;
  border-top: 1px solid var(--secondary-border-color);
  padding: 10px 0 4px;
  height: 180px;

  .history-placeholder {
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-base-sm);
  }
}

.history-block-list {
  .history-block-note {
    border-bottom: 1px solid var(--secondary-border-color);
    padding: 20px 0 16px;
  }

  .note-title {
    display: block;
    margin-bottom: 8px;
  }

  .note-text {
    font-weight: var(--font-weight-light);

    &-title {
      font-weight: var(--font-weight-bold);
    }
  }

  .note-rating {
    display: flex;
    align-items: center;
    font-size: var(--font-size-base-sm);
    gap: 5px;
  }
}
</style>
