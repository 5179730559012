<template>
  <div class="job-info">
    <template v-if="isModalOpen">
      <AppSelect
        :value.sync="ticketObj.industry"
        :items="industries"
        label="Industry:"
        placeholder="Start Typing"
        class="industries-select"
      />
      <AppSelect
        :value.sync="ticketObj.jobCategory"
        :items="jobCategories"
        label="Job Category:"
        placeholder="Start Typing"
        class="job-categories-select"
      />
      <GmapLocationInput
        v-model="ticketObj.jobLocation"
        label="Job Location"
        placeholder="Start Typing"
        class="job-location-input"
      />
    </template>
    <AppInput
      :value.sync="ticketObj.jobPostingLink"
      label="Link to the Job Posting"
      placeholder="Post URL"
      class="job-link-input"
    />
    <AppTextarea
      :value.sync="ticketObj.postingText"
      rows="7"
      label="Copy and Paste the Posting Text"
      class="posting-textarea"
    />
  </div>
</template>

<script>
import GmapLocationInput from '@/components/GmapLocationInput'

export default {
  name: 'TicketModalJobInfo',
  components: {
    GmapLocationInput
  },
  props: {
    ticket: {
      type: Object,
      required: true
    },
    isModalOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      industries: [
        'Accounting / Finance',
        'Apparel / Fashion',
        'Arts / Music / Journalism',
        'Automotive',
        'Aviation / Aerospace',
        'Banking / Financial Services',
        'Business Services / Consulting',
        'Charity / Non-Profit',
        'Construction / Civil',
        'Education',
        'Employment / Recruiting / Staffing',
        'Environmental',
        'Food / Beverage',
        'Government Services',
        'Hospitality / Retail / Restaurants',
        'Information Technology',
        'Legal / Human Resources',
        'Marketing / Advertising / PR',
        'Media / Entertainment',
        'Medical / Healthcare',
        'Oil / Mining / Energy',
        'Manufacturing',
        'Packaging / Paper',
        'Real Estate / Facilities',
        'Telecommunications / Wireless',
        'Transportation / Warehousing'
      ],
      jobCategories: [
        'Accounting',
        'Administrative',
        'Advertising',
        'Agricultural',
        'Analyst',
        'Analytics',
        'Architecture',
        'Arts',
        'Banking',
        'Business Development',
        'Construction',
        'Consulting',
        'Customer Service',
        'Distribution',
        'Education',
        'Engineering',
        'Environmental',
        'Equipment Operations',
        'Events',
        'Executive',
        'Finance',
        'General Labor',
        'Human Resources',
        'Information Technology',
        'Insurance',
        'Legal',
        'Logistics',
        'Maintenance',
        'Manufacturing',
        'Marketing',
        'Mathematics',
        'Other',
        'Personal Care',
        'Product Development',
        'Production',
        'Project Management',
        'Psychology',
        'Public Relations',
        'Purchasing',
        'Quality Assurance',
        'Research',
        'Sales',
        'Scientific',
        'Skilled Trade',
        'Statistics',
        'Training',
        'Transportation',
        'Writing'
      ],
      priorityNumber: 0,
      prioritySelected: null,
      priorityList: [
        {
          id: 1,
          title: 'Highly desirable but unlikely to happen',
          value: 'first'
        },
        {
          id: 2,
          title: 'Highly desirable',
          value: 'second'
        },
        {
          id: 3,
          title: 'Hopeful to get this job',
          value: 'third'
        },
        {
          id: 4,
          title: 'Not my top choice but will consider an offer',
          value: 'fourth'
        },
        {
          id: 5,
          title: 'Applying just in case',
          value: 'fifth'
        }
      ]
    }
  },
  computed: {
    ticketObj: {
      get() {
        return this.ticket
      },
      set(value) {
        this.$emit('update:ticket', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.job-info {
  display: grid;
  gap: 16px;
}

.industries-select,
.job-categories-select {
  grid-column: 1 / 3;
}

.job-location-input,
.job-link-input,
.posting-textarea {
  grid-column: 1 / 4;
}

.ticket-color-picker {
  .color-picker-title {
    display: block;
    margin-bottom: 11px;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-base-sm);
  }

  .color-picker-list {
    display: grid;
    padding: 0;
    gap: 11px;
  }

  .color-picker-item {
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-sm);

    .active {
      font-weight: var(--font-weight-bold);
    }
  }
}
</style>
