<template>
  <div class="gmap-input">
    <span v-if="label" class="label">{{ label }}</span>
    <gmap-autocomplete
      :value="value.address || ''"
      :placeholder="placeholder"
      class="gmap-autocomplete-input"
      @place_changed="locationChanged"
    >
      <template v-slot:input="autocompleteSlotProps">
        <v-text-field
          ref="input"
          outlined
          background-color="#fff"
          @listeners="autocompleteSlotProps.listeners"
          @attrs="autocompleteSlotProps.attrs"
        />
      </template>
    </gmap-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'GmapsPlaceInput',
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {}
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    locationChanged(location) {
      this.$emit('input', JSON.parse(JSON.stringify({
        address: location.formatted_address,
        lat: location.geometry.location.lat(),
        lng: location.geometry.location.lng()
      })))
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  margin-bottom: 5px;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.gmap-autocomplete-input {
  outline: none;
  border: var(--default-border);
  border-radius: var(--border-radius-small);
  padding: 8px;
  width: 100%;
  height: 34px;

  &:focus {
    border: 2px solid var(--primary-border-color);
  }
}
</style>
